<template>
	<div class="osahan-signup">
		<div class="mt-8 p-3">
			<h2 class="mb-4 text-center text-magenta">Create an account</h2>
			<form action="verification.html" class="mt-8">
				<div class="form-group pr-3 pl-3">
					<input
						placeholder="Enter Nom"
						type="text"
						class="form-control"
						v-model="form.name"
						id="input_name1"
						aria-describedby="emailHelp"
					/>
					<span class="text-danger pr-2" v-if="$root.errors.name">
						{{ $root.errors.name.join(',') }}
					</span>
				</div>
				<div class="form-group pr-3 pl-3">
					<div class="flex">
						<vue-country-code
							class="mr-1 transition duration-100 ease-in-out border rounded focus:ring-2 disabled:opacity-50 disabled:cursor-not-allowed text-black placeholder-gray-400 bg-white border-gray-300 text-sm"
							@onSelect="
								({ dialCode }) => (form.dialCode = dialCode)
							"
						/>
						<input
							placeholder="Enter Telephone"
							type="text"
							v-model="form.phone"
							class="form-control"
							id="input_number1"
							aria-describedby="emailHelp"
						/>
					</div>
					<span class="text-danger pr-2" v-if="$root.errors.phone">
						{{ $root.errors.phone.join(',') }}
					</span>
				</div>
				<div class="form-group pr-3 pl-3">
					<input
						placeholder="Enter Email"
						type="email"
						v-model="form.email"
						class="form-control"
						id="input_email1"
						aria-describedby="emailHelp"
					/>
					<span class="text-danger pr-2" v-if="$root.errors.email">
						{{ $root.errors.email.join(',') }}
					</span>
				</div>
				<div class="form-group pr-3 pl-3">
					<input
						placeholder="Enter Password"
						type="password"
						v-model="form.password"
						class="form-control"
						id="input_password1"
					/>
					<span class="text-danger pr-2" v-if="$root.errors.password">
						{{ $root.errors.password.join(',') }}
					</span>
				</div>
				<div class="form-group pr-3 pl-3">
					<input
						placeholder="Enter Confirmation Password"
						type="password"
						v-model="form.confirm"
						class="form-control"
						id="input_password2"
					/>
					<span class="text-danger pr-2" v-if="$root.errors.confirm">
						{{ $root.errors.confirm.join(',') }}
					</span>
				</div>
				<button
					@click="register"
					type="button"
					class="btn bg-magenta text-white rounded btn-lg btn-block mt-10  pr-3 pl-3"
				>
					S I G N &nbsp; &nbsp; U P
				</button>
				<router-link
					:to="{ name: 'auth-login' }"
					class="btn btn-block btn-lg"
					>You already have an account? Login
				</router-link>
			</form>
		</div>
	</div>
</template>

<script>
import { cloneObject } from '@/tools/helpers.js';
import { ApiRegisterClient } from '@/tools/apiService.js';
import { mapActions } from 'vuex';
export default {
	data: () => ({
		form: {}
	}),
	mounted: function() {
		if (this.$route.query.referal) {
			localStorage.setItem('referal', this.$route.query.referal);
		}
	},
	methods: {
		...mapActions({
			saveToken: 'auth/saveToken'
		}),
		register: function() {
			let form = cloneObject(this.form);
			form.referal = localStorage.getItem('referal');
			form.phone = `${form.dialCode} ${form.phone}`;
			ApiRegisterClient(form).then(response => {
				localStorage.removeItem('referal');
				this.saveToken({
					token: response.data.token
				});
				this.$router.push({
					name: 'user-commands'
				});
			});
		}
	}
};
</script>

<style>
.fixed-bottom {
	border-radius: 0px;
	box-shadow: none;
	height: 59px;
}
</style>
